import Image from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'

import { Box, CardActionArea } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'

import { Title } from '~/components/shared/Text'

import BoatIcon from '~/assets/icons/specifications/boat.svg?sprite'
import CarIcon from '~/assets/icons/specifications/caravan.svg?sprite'

import { getEntityByBrand } from '~/brands'

import SimpleCard from './SimpleCard'

const PlaceholderIcon = getEntityByBrand<typeof CarIcon>({
  rv: CarIcon,
  boat: BoatIcon
})

type Props = {
  category: string
  href: string
  icon: string
}

const CategoryCard: FC<Props & BoxProps> = ({
  category,
  href,
  icon,
  ...rest
}) => {
  return (
    <SimpleCard padding={0} {...rest}>
      <Link href={href} passHref prefetch={false}>
        <CardActionArea>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={135}
            width={135}
            px={1.25}
            pb={2}
          >
            <div style={{ position: 'relative', width: 80, height: 80 }}>
              {icon ? (
                <Image
                  src={icon}
                  alt={category}
                  layout="fill"
                  objectFit="contain"
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <PlaceholderIcon style={{ width: 50, height: 50 }} />
                </Box>
              )}
            </div>
            <Title
              textTransform="uppercase"
              color="primary"
              textAlign="center"
              fontSize={14}
              variant="button"
              as="h2"
              fontWeight={400}
              letterSpacing={0.4}
              lineHeight={1.25}
            >
              {category}
            </Title>
          </Box>
        </CardActionArea>
      </Link>
    </SimpleCard>
  )
}

export default CategoryCard
