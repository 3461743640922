import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export const useMobile = (): boolean => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile
}

export const useDesktop = (): boolean => {
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return isDesktop
}
